<template>

<div class="orders account-section-main" v-if="!order_id">
	<h2>My Orders</h2>

	<div class="loading" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>

	<div class="no-results" v-else-if="!orders || orders.length == 0">
		<h3 v-if="ordersMessage">{{ this.ordersMessage }}</h3>
		<h3 v-else>No orders to display yet!</h3>
	</div>

	<table class="orders orders-list-mobile" cellspacing="0" cellpadding="0" v-else>
		<tr>
			<th class="left">Date</th>
			<th class="left">Number</th>
			<th class="left">Type</th>
			<th class="left">Status</th>
			<th class="right">Total</th>
		</tr>
		<router-link tag="tr" :to="'/myaccount/orders/' + order.order_id" v-for="(order, index) in orders" :key="'order-' + order.order_id">
			<td>{{ order.order_date }}</td>
			<td>{{ order.order_number }}</td>
			<td>{{ order.order_method }}</td>
			<td>{{ order.order_status }}</td>
			<td class="right">${{ order.order_total }}</td>
		</router-link>
	</table>
</div>

<div class="orders account-section-main" v-else id="print">
	<h2>My Orders</h2>
	<div class="loading" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
	
	<div class="order-detail">
		<div class="order-detail-section">
			<div>
				<span class="label">Order Number</span>
				<span class="label-detail">{{ order_detail.order_number }}</span><br>
			</div>
			<div>
				<span class="label">Order Date</span>
				<span class="label-detail">{{ order_detail.display_date }}</span>
			</div>
			<div>
				<span class="label">Order Total</span>
				<span class="label-detail"><sup>$</sup>{{ order_totals.order_total }}</span>
			</div>
		</div>
		
		<div class="order-detail-section">
			<div>
				<template v-if="order_billing_address.length == 0">Default Customer</template>
				<template v-else>
					{{ order_billing_address.first_name + ' ' + order_billing_address.last_name }}<br>
					{{ order_billing_address.address_1 }}<br>
					<span v-if="order_billing_address.address_2">{{ order_billing_address.address_2 }}</span>
					{{ order_billing_address.city + ' ' + order_billing_address.state + ' ' + order_billing_address.zip_code }}
				</template>
			</div>
			<div>
				<span class="label">Email</span>
				<span class="label-detail">{{ order_billing_address.email_address }}</span>
			</div>
			<div>
				<span class="label">Phone</span>
				<span class="label-detail">{{ order_billing_address.phone_number }}</span>
			</div>
		</div>

		<div class="order-detail-table">
			<h3>Items</h3>
			<table cellpadding="0" cellspacing="0" class="items-table">
				<thead>
					<th class="left">Description</th>
					<th class="left">UPC</th>
					<th class="left">Qty</th>
					<th class="right">Item Total</th>
				</thead>
				<tbody>
					<template v-for="item in order_items">
						<tr>
							<td @click="toggleDetail('item_' + item.order_item_id)">{{ item.description }}</td>
							<td>{{ item.upc }}</td>
							<td>{{ item.quantity }}</td>
							<td class="right"><sup>$</sup>{{ item.order_price }}</td>
						</tr>
						<template v-if="openDetails.indexOf('item_' + item.order_item_id) > -1">
							<tr>
								<td colspan="5">
									<div class="detail" v-for="detail in item.detail">
										<div>{{ detail.description }}</div>
										<button v-if="detail.order_number" @click="$router.push('/return/' + detail.order_number)">View Order</button>
									</div>
								</td>
							</tr>
						</template>
					</template>
				</tbody>
			</table>
		</div>

		<div class="order-detail-table">
			<h3>Payments</h3>
			<table cellpadding="0" cellspacing="0" class="items-table">
				<thead>
					<th class="left">Payment Date</th>
					<th class="left">Payment Type</th>
					<th class="right">Amount</th>
				</thead>
				<tbody>
					<template v-for="payment in order_payments">
						<tr>
							<td class="live" @click="toggleDetail('payment_' + payment.order_payment_id)">{{ payment.payment_date }}</td>
							<td class="live" @click="toggleDetail('payment_' + payment.order_payment_id)">{{ payment.description + (payment.amount < 0 ? ' Refund' : '') }}</td>
							<td class="right live" @click="toggleDetail('payment_' + payment.order_payment_id)"><sup>$</sup>{{ payment.amount }}</td>
						</tr>
						<template v-if="openDetails.indexOf('payment_' + payment.order_payment_id) > -1">
							<tr>
								<td colspan="5">
									<div class="detail" v-if="payment.authorization_code">
										<div>
											<span class="label">{{ payment.card_type || 'Number' }}</span> {{ payment.reference_number }}
											<div v-if="payment.authorization_code"><span class="label">Auth Code</span> {{ payment.authorization_code }}<br><span class="label">Transaction ID</span> {{ payment.transaction_identifier }}</div>
										</div>
										<div>
											<div v-if="payment.cardholder_name" style="font-size: 0.8em; margin: 0 0 0.5em 0;">{{ payment.cardholder_name }}</div>
											<img v-if="payment.signature" :src="'data:image/png;base64,' + payment.signature" style="height: 50px; border: 2px solid #fff;">
										</div>
									</div>
									<div class="detail" v-else>
										{{ payment.description }}, no detail
									</div>
									<div class="detail" v-for="detail in payment.detail">
										<div>{{ detail }}</div>
									</div>
								</td>
							</tr>
						</template>
					</template>
				</tbody>
			</table>
		</div>

		<div class="order-detail-section">
			<h3>Totals</h3>
			<div class="order-totals">
				<div class="layaway-detail" v-if="order_totals.balance_remaining > 0">
					<span class="label-detail">Layaway Balance:</span>
					<span class="label-detail">${{ order_totals.balance_remaining }}</span>
				</div>
				<div class="order-totals-detail">
					<span class="label">Subtotal</span>
					<span class="label-detail"><sup>$</sup>{{ order_totals.order_subtotal }}</span>

					<span class="label">Discounts</span>
					<span class="label-detail"><sup>$</sup>{{ order_totals.discount_amount ? (order_totals.discount_amount * -1).toFixed(2) : '0.00' }}</span>

					<span class="label">Tax</span>
					<span class="label-detail"><sup>$</sup>{{ order_totals.tax_charge }}</span>

					<span class="label">Fees</span>
					<span class="label-detail"><sup>$</sup>{{ order_totals.fee_total }}</span>

					<span class="label">Total</span>
					<span class="label-detail total-large"><sup>$</sup>{{ order_totals.order_total }}</span>
				</div>
			</div>
		</div>
	
		<!-- v-if="order_totals.balance_remaining > 0" -->

	</div>
	
	<div class="controls">
		<div class="button action" @click="print()">Print Order</div>
		<div class="button action" @click="$router.push('/myaccount/orders')">Back to Orders</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'my-orders',
	props: ['order_id'],
	data() {
		return {
			loading: true,
			orders: [],
			order_billing_address: {},
			order_date: {},
			order_detail: {},
			order_discounts: {},
			order_fees: {},
			order_items: {},
			order_payments: {},
			order_shipping_address: {},
			order_time: {},
			order_totals: {},
			range_items: {},
			openDetails: [],
			ordersMessage: null
		}
	},
	created() {
		if (this.order_id) {
			this.fetchOrder()
		} else {
			this.fetchOrders()
		}
	},
	methods: {
		fetchOrder() {
			this.apiCall({ destination: 'get_item_order', data: { order_id: this.order_id } }).then(obj => {
				this.openDetails = []
				this.order = obj
				for (var key in obj) {
					if (key.indexOf('order_') > -1 || key.indexOf('range_items') > -1) {
						this[key] = obj[key]
					}
				}
				this.loading = false
            }).catch(error => {
				this.loading = false
                console.log(error)
            })
		},
		fetchOrders() {
			if (this.getUserContactId) {
				this.apiCall({ destination: 'get_list_orders', data: { contact_id: this.getUserContactId } }).then(obj => {
					this.orders = obj.transactions
					this.loading = false
	            }).catch(error => {
					this.loading = false
	                console.log(error)
	            })
			} else {
				this.apiCall({ destination: 'get_item_user' }).then(obj => {
					this.user = obj.user_detail
					this.apiCall({ destination: 'get_list_orders', data: { contact_id: obj.user_detail.contact_id } }).then(obj => {
						this.orders = obj.transactions
						this.loading = false
		            }).catch(error => {
						this.loading = false
		                console.log(error)
		            })
	            }).catch(error => {
					this.loading = false
	                console.log(error)
	            })
			}
		},
		toggleDetail(id) {
			let index = this.openDetails.indexOf(id)
			if (index > -1) {
				this.openDetails.splice(index, 1)
			} else {
				this.openDetails.push(id)
			}
		},
		print() {
			var prtContent = document.getElementById("print");
			var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
			WinPrint.document.write(prtContent.innerHTML);
			WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			WinPrint.close();
		}
	}
}
</script>

<style lang="scss" scoped>

.orders {
	tr:nth-child(even) { 
		background-color: #f1f1f1; 
	} 
}

.orders-list-mobile {
	@media (max-width: 650px) {
		td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4) {
			display: none;
		}
	}
}

.order-detail {
	.order-detail-section {
		display: grid;
		grid-auto-flow: column;
		padding: 0.75em 0;
		border-bottom: 1px solid #939393;
		.label, .label-detail {
			display: block;
		}
		.order-totals {
			.layaway-detail {
				border-bottom: 1px solid #939393;
			}
			.layaway-detail, .order-totals-detail {
				padding: 0.75em 0;
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-items: end;
				.total-large {
					font-size: 1.5em;
					font-weight: bolder;
				}
			}
		}
	}
	@media (max-width: 768px) {
		.order-detail-section {
			grid-auto-flow: row;
		}
	}
	.order-detail-table {
		padding: 0.75em 0;
		border-bottom: 1px solid #939393;
		.items-table {
			tr:nth-child(even) { 
				background-color: #f1f1f1; 
			} 	
		}
	}
}

.controls {
	padding: 0.75em 0;
	display: grid;
    grid-auto-flow: column;
    width: fit-content;
}
.account-buttons {
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
}
</style>